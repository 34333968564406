define("discourse/plugins/discourse-patreon/discourse/controllers/admin-plugins-patreon", ["exports", "@ember/controller", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-patreon/discourse/models/filter-rule"], function (_exports, _controller, _service, _ajax, _ajaxError, _getUrl, _decorators, _I18n, _filterRule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("rewards"), (_obj = {
    dialog: (0, _service.inject)(),
    prettyPrintReward: reward => {
      return `$${reward.amount_cents / 100} - ${reward.title}`;
    },
    rewardsNames() {
      return Object.values(this.rewards).filter(r => r.id >= 0).map(r => this.prettyPrintReward(r));
    },
    editing: _filterRule.default.create({
      group_id: null
    }),
    actions: {
      save() {
        const rule = this.get("editing");
        const model = this.get("model");
        rule.set("group", this.groups.find(x => x.id === parseInt(rule.get("group_id"), 10)));
        rule.set("rewards_ids", Object.values(this.rewards).filter(v => rule.get("reward_list").includes(this.prettyPrintReward(v))).map(r => r.id));
        (0, _ajax.ajax)("/patreon/list.json", {
          method: "POST",
          data: rule.getProperties("group_id", "rewards_ids")
        }).then(() => {
          let obj = model.find(x => x.get("group_id") === rule.get("group_id"));
          const rewards = rule.get("reward_list").filter(Boolean);
          if (obj) {
            obj.set("reward_list", rewards);
            obj.set("rewards", rewards);
            obj.set("rewards_ids", rule.rewards_ids);
          } else {
            model.pushObject(_filterRule.default.create({
              group: rule.get("group.name"),
              rewards
            }));
          }
          this.set("editing", _filterRule.default.create({
            group_id: null
          }));
        }).catch(_ajaxError.popupAjaxError);
      },
      delete(rule) {
        const model = this.get("model");
        (0, _ajax.ajax)("/patreon/list.json", {
          method: "DELETE",
          data: rule.getProperties("group_id")
        }).then(() => {
          let obj = model.find(x => x.get("group_id") === rule.get("group_id"));
          model.removeObject(obj);
        }).catch(_ajaxError.popupAjaxError);
      },
      updateData() {
        this.set("updatingData", true);
        (0, _ajax.ajax)("/patreon/update_data.json", {
          method: "POST"
        }).catch(_ajaxError.popupAjaxError).finally(() => this.set("updatingData", false));
        this.messageBus.subscribe("/patreon/background_sync", () => {
          this.messageBus.unsubscribe("/patreon/background_sync");
          this.set("updatingData", false);
          const refreshUrl = (0, _getUrl.default)("/admin/plugins/patreon");
          this.dialog.alert({
            message: _I18n.default.t("patreon.refresh_page"),
            didConfirm: () => window.location.pathname = refreshUrl,
            didCancel: () => window.location.pathname = refreshUrl
          });
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "rewardsNames", [_dec], Object.getOwnPropertyDescriptor(_obj, "rewardsNames"), _obj)), _obj)));
});